//LOGIN
export const ADD_LOGIN = "ADD_LOGIN";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const REMOVE_LOGIN = "REMOVE_LOGIN";

//REGISTER
export const POST_REGISTER = "POST_REGISTER";
export const POST_MAIL = "POST_MAIL";
export const FETCH_REGISTER = "FETCH_REGISTER";
export const FETCH_COMPLETED = "FETCH_COMPLETED";
export const EDIT_REGISTER = "EDIT_REGISTER";
export const DELETE_REGISTER = "DELETE_REGISTER";
export const REGISTER_LOADING = "REGISTER_LOADING";
export const REGISTER_FAILED = "REGISTER_FAILED";
export const FETCH_BARCODE = "FETCH_BARCODE";
export const FETCH_COUNT = "FETCH_COUNT";
export const FETCH_REPORT = "FETCH_REPORT";

//PERSONALINFO
export const POST_PERSONAL = "POST_PERSONAL";
export const FETCH_PERSONAL = "FETCH_PERSONAL";
export const EDIT_PERSONAL = "EDIT_PERSONAL";
export const DELETE_PERSONAL = "DELETE_PERSONAL";
export const PERSONAL_LOADING = "PERSONAL_LOADING";
export const PERSONAL_FAILED = "PERSONAL_FAILED";

//VISAINFO
export const POST_VISA = "POST_VISA";
export const FETCH_VISA = "FETCH_VISA";
export const EDIT_VISA = "EDIT_VISA";
export const DELETE_VISA = "DELETE_VISA";
export const VISA_LOADING = "VISA_LOADING";
export const VISA_FAILED = "VISA_FAILED";

//USER
export const POST_USER = "POST_USER";
export const FETCH_USER = "FETCH_USER";
export const EDIT_USER = "EDIT_USER";
export const DELETE_USER = "DELETE_USER";
export const USER_LOADING = "USER_LOADING";
export const USER_FAILED = "USER_FAILED";

//BRANCH
export const POST_BRANCH = "POST_BRANCH";
export const FETCH_BRANCH = "FETCH_BRANCH";
export const EDIT_BRANCH = "EDIT_BRANCH";
export const DELETE_BRANCH = "DELETE_BRANCH";
export const BRANCH_LOADING = "BRANCH_LOADING";
export const BRANCH_FAILED = "BRANCH_FAILED";

//CORPORATE
export const POST_CORPORATE = "POST_CORPORATE";
export const FETCH_CORPORATE = "FETCH_CORPORATE";
export const EDIT_CORPORATE = "EDIT_CORPORATE";
export const DELETE_CORPORATE = "DELETE_CORPORATE";
export const CORPORATE_LOADING = "CORPORATE_LOADING";
export const CORPORATE_FAILED = "CORPORATE_FAILED";

//AGENT
export const POST_AGENT = "POST_AGENT";
export const FETCH_AGENT = "FETCH_AGENT";
export const EDIT_AGENT = "EDIT_AGENT";
export const DELETE_AGENT = "DELETE_AGENT";
export const AGENT_LOADING = "AGENT_LOADING";
export const AGENT_FAILED = "AGENT_FAILED";

//UPDATE
export const POST_UPDATE = "POST_UPDATE";
export const FETCH_UPDATE = "FETCH_UPDATE";
export const EDIT_UPDATE = "EDIT_UPDATE";
export const DELETE_UPDATE = "DELETE_UPDATE";
export const UPDATE_LOADING = "UPDATE_LOADING";
export const UPDATE_FAILED = "UPDATE_FAILED";

//HOLIDAY
export const POST_HOLIDAY = "POST_HOLIDAY";
export const FETCH_HOLIDAY = "FETCH_HOLIDAY";
export const EDIT_HOLIDAY = "EDIT_HOLIDAY";
export const DELETE_HOLIDAY = "DELETE_HOLIDAY";
export const HOLIDAY_LOADING = "HOLIDAY_LOADING";
export const HOLIDAY_FAILED = "HOLIDAY_FAILED";

//REQUIREMENT
export const POST_REQUIREMENT = "POST_REQUIREMENT";
export const POST_REQUIREMENTMAIL = "POST_REQUIREMENTMAIL";
export const FETCH_REQUIREMENT = "FETCH_REQUIREMENT";
export const FETCH_REQUIREMENTTYPE = "FETCH_REQUIREMENTTYPE";
export const EDIT_REQUIREMENT = "EDIT_REQUIREMENT";
export const DELETE_REQUIREMENT = "DELETE_REQUIREMENT";
export const REQUIREMENT_LOADING = "REQUIREMENT_LOADING";
export const REQUIREMENT_FAILED = "REQUIREMENT_FAILED";
