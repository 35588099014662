import * as ActionTypes from "../Types/ActionTypes";

export const Requirement = (
  state = { isLoading: true, errMess: null, requirement: [], type: [] },
  action
) => {
  switch (action.type) {
    case ActionTypes.FETCH_REQUIREMENT:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        requirement: action.payload
      };

    case ActionTypes.FETCH_REQUIREMENTTYPE:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        type: action.payload
      };

    case ActionTypes.REQUIREMENT_LOADING:
      return { ...state, isLoading: true, errMess: null, requirement: [] };

    case ActionTypes.REQUIREMENT_FAILED:
      return {
        ...state,
        isLoading: false,
        errMess: action.payload,
        requirement: [],
        count: []
      };

    default:
      return state;
  }
};
