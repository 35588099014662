import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./Login.css";
import Notification from "../Components/Notifications/Notification";
import { Formik, Form, Field } from "formik";
import { postLogin } from "../../redux/Creators/LoginCreators";
import { Input } from "reactstrap";

const CustomInputComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => <Input {...field} {...props} />;

const mapStateToProps = state => {
  return {
    login: state.login
  };
};

const mapDispatchToProps = dispatch => ({
  postLogin: data => {
    dispatch(postLogin(data));
  }
});

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      type: "",
      message: ""
    };
  }

  toggleModal() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  handleSubmit = (values, { props = this.props, setSubmitting }) => {
    let data = {
      email: values.email,
      password: values.password
    };

    setSubmitting(true);
    props.postLogin(data);
    return;
  };

  render() {
    if (this.props.login.login != "") {
      return <Redirect to={"/dashboard"} />;
    }
    return (
      <React.Fragment>
        <div className="authentications">
          <Notification
            open={this.state.isOpen}
            toggle={this.toggleModal}
            type={this.state.type}
            message={this.state.message}
          />
          <div
            className="auth-wrapper align-items-center d-flex bg"
            style={{ minHeight: "100vh" }}
          >
            <div className="container ">
              <div
                className="no-gutters justify-content-center row"
                style={{ marginTop: "12%", marginBottom: "14%" }}
              >
                <div className="bg-white col-md-6 col-lg-4 d-block d-sm-block d-md-none d-lg-none">
                  <h1>Not Authorized</h1>
                </div>
                <div className="bg-white col-md-6 col-lg-4 d-none d-md-block d-xl-block">
                  <div className="p-4">
                    <h3 className="font-medium mb-3">Sign In to Admin</h3>
                    <Formik
                      initialValues={{
                        email: "",
                        password: ""
                      }}
                      onSubmit={this.handleSubmit}
                      render={formProps => {
                        return (
                          <Form>
                            <label htmlFor="email" className="font-medium">
                              Email
                            </label>
                            <div className="mb-2 input-group input-group-lg">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="ti-user" />
                                </span>
                              </div>
                              <Field
                                component={CustomInputComponent}
                                type="email"
                                name="email"
                                id="email"
                                placeholder="Enter Email"
                              />
                            </div>

                            <div className="error mb-2" />

                            <label
                              htmlFor="password"
                              className="mt-3 font-medium"
                            >
                              Password
                            </label>
                            <div className="mb-3 input-group input-group-lg">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="ti-lock" />
                                </span>
                              </div>
                              <Field
                                component={CustomInputComponent}
                                type="password"
                                name="password"
                                id="password"
                                placeholder="Enter Password"
                              />
                            </div>

                            <div className="error mb-2" />

                            {/* <div className="d-flex no-block align-items-center mb-4 mt-4">
                            <div className="custom-checkbox custom-control">
                              <input
                                type="checkbox"
                                id="exampleCustomCheckbox"
                                className="custom-control-input"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="exampleCustomCheckbox"
                              >
                                Remeber Me
                              </label>
                            </div>
                          </div> */}

                            <div className="mb-3 row">
                              <div className="col-12">
                                <button
                                  type="submit"
                                  disabled={formProps.isSubmitting}
                                  className="btn btn-primary btn-lg btn-block"
                                >
                                  Log In
                                </button>
                              </div>
                            </div>
                          </Form>
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
