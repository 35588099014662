import * as ActionTypes from "../Types/ActionTypes";

export const AddRegister = (
  state = { isLoading: true, errMess: null, register: [], edit: [], delete: [], mail: [] },
  action
) => {
  switch (action.type) {
    case ActionTypes.POST_REGISTER:
      let data = [];
      data.push(action.payload);
      return {
        ...state,
        isLoading: false,
        errMess: null,
        register: data,
        edit: [],
        delete: []
      };
    
    case ActionTypes.POST_MAIL:
      let maildata = [];
      maildata.push(action.payload);
      return {
        ...state,
        isLoading: false,
        errMess: null,
        mail: maildata,
        edit: [],
        delete: []
      };

    case ActionTypes.EDIT_REGISTER:
      let editData = [];
      editData.push(action.payload);
      return {
        ...state,
        isLoading: false,
        errMess: [],
        edit: editData,
        delete: [],
        register: []
      };

    case ActionTypes.DELETE_REGISTER:
      let deleteData = [];
      deleteData.push(action.payload);
      return {
        ...state,
        isLoading: false,
        errMess: null,
        delete: deleteData,
        register: [],
        mail: [],
        edit: []
      };

    case ActionTypes.REGISTER_LOADING:
      return {
        ...state,
        isLoading: true,
        errMess: null,
        register: [],
        delete: []
      };

    case ActionTypes.REGISTER_FAILED:
      return {
        ...state,
        isLoading: false,
        errMess: action.payload,
        register: [],
        delete: []
      };

    default:
      return state;
  }
};
