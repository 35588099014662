var AccountRoutes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: "fas fa-tachometer-alt",
    },
    {
        path: "/accountapplication",
        name: "Register Application",
        icon: "fas fa-rupee-sign",
    },
    {
        path: "/requirements",
        name: "Requirements",
        icon: "fas fa-clipboard",
    },
    {
        path: "/search",
        name: "Barcode Search",
        icon: "fas fa-barcode",
    },
    {
        path: "/updates",
        name: "News & Updates",
        icon: "fas fa-rss"
      },
      {
        path: "/holidays",
        name: "Consulate Holidays",
        icon: "fas fa-birthday-cake"
      },
    { path: "/", pathTo: "/dashboard", name: "Dashboard", redirect: true },
    { path: "/login", pathTo: "/login", name: "Login", redirect: true }
];
export default AccountRoutes;