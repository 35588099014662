import React, { Component } from "react";
import { Alert } from "reactstrap";

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      type: "",
      message: ""
    };
  }

  toggleModal() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    return (
      <React.Fragment>
        <Alert
          color={this.props.type}
          isOpen={this.props.open}
          toggle={this.props.toggle}
        >
          <center>{this.props.message}</center>
        </Alert>
      </React.Fragment>
    );
  }
}

export default Notification;
