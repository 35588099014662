import * as ActionTypes from "../Types/ActionTypes";

export const Agent = (
  state = { isLoading: true, errMess: null, agent: [] },
  action
) => {
  switch (action.type) {
    case ActionTypes.FETCH_AGENT:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        agent: action.payload
      };

    case ActionTypes.AGENT_LOADING:
      return { ...state, isLoading: true, errMess: null, agent: [] };

    case ActionTypes.AGENT_FAILED:
      return {
        ...state,
        isLoading: false,
        errMess: action.payload,
        agent: []
      };

    default:
      return state;
  }
};
