import * as ActionTypes from "../Types/ActionTypes";

export const Personal = (
  state = { isLoading: true, errMess: null, personal: [] },
  action
) => {
  switch (action.type) {
    case ActionTypes.FETCH_PERSONAL:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        personal: action.payload
      };

    case ActionTypes.PERSONAL_LOADING:
      return { ...state, isLoading: true, errMess: null, personal: [] };

    case ActionTypes.PERSONAL_FAILED:
      return {
        ...state,
        isLoading: false,
        errMess: action.payload,
        personal: []
      };

    default:
      return state;
  }
};
