import * as ActionTypes from "../Types/ActionTypes";

export const Update = (
  state = { isLoading: true, errMess: null, update: [] },
  action
) => {
  switch (action.type) {
    case ActionTypes.FETCH_UPDATE:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        update: action.payload
      };

    case ActionTypes.UPDATE_LOADING:
      return { ...state, isLoading: true, errMess: null, update: [] };

    case ActionTypes.UPDATE_FAILED:
      return {
        ...state,
        isLoading: false,
        errMess: action.payload,
        update: []
      };

    default:
      return state;
  }
};
