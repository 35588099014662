import React from "react";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer text-center">
        All Rights Reserved. Developed by{" "}
        <a href="https://www.uditsolutions.in">UD IT Solutions</a>.
      </footer>
    );
  }
}
export default Footer;
