var AdminRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "fas fa-tachometer-alt"
  },
  {
    path: "/application",
    name: "Register Application",
    icon: "fas fa-rupee-sign"
  },
  {
    path: "/users",
    name: "Users",
    icon: "fas fa-users"
  },
  {
    path: "/branch",
    name: "Branch",
    icon: "fas fa-building"
  },
  {
    path: "/corporate",
    name: "Corporate",
    icon: "fas fa-industry"
  },
  {
    path: "/agent",
    name: "Agent",
    icon: "fas fa-user"
  },
  {
    path: "/requirements",
    name: "Requirements",
    icon: "fas fa-clipboard"
  },
  {
    path: "/search",
    name: "Barcode Search",
    icon: "fas fa-barcode"
  },
  {
    path: "/reports",
    name: "Reports",
    icon: "fas fa-file-excel"
  },
  {
    path: "/updates",
    name: "News & Updates",
    icon: "fas fa-rss"
  },
  {
    path: "/holidays",
    name: "Consulate Holidays",
    icon: "fas fa-birthday-cake"
  },
  { path: "/", pathTo: "/dashboard", name: "Dashboard", redirect: true },
  { path: "/login", pathTo: "/login", name: "Login", redirect: true }
];
export default AdminRoutes;
