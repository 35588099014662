import * as ActionTypes from "../Types/ActionTypes";

export const Holiday = (
  state = { isLoading: true, errMess: null, holiday: [] },
  action
) => {
  switch (action.type) {
    case ActionTypes.FETCH_HOLIDAY:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        holiday: action.payload
      };

    case ActionTypes.HOLIDAY_LOADING:
      return { ...state, isLoading: true, errMess: null, holiday: [] };

    case ActionTypes.HOLIDAY_FAILED:
      return {
        ...state,
        isLoading: false,
        errMess: action.payload,
        holiday: []
      };

    default:
      return state;
  }
};
