import { createStore, applyMiddleware } from "redux";
import { persistStore, persistCombineReducers } from "redux-persist";
import thunk from "redux-thunk";
import storage from "redux-persist/es/storage";
import { Login } from "./Reducer/login";
import { Agent } from "./Reducer/agent";
import { Branch } from "./Reducer/branch";
import { Corporate } from "./Reducer/corporate";
import { Holiday } from "./Reducer/holiday";
import { Personal } from "./Reducer/personal";
import { Register } from "./Reducer/register";
import { Requirement } from "./Reducer/requirement";
import { Update } from "./Reducer/update";
import { User } from "./Reducer/user";
import { Visa } from "./Reducer/visa";
import { AddAgent } from "./Reducer/addAgent";
import { AddBranch } from "./Reducer/addBranch";
import { AddCorporate } from "./Reducer/addCorporate";
import { AddHoliday } from "./Reducer/addHoliday";
import { AddPersonal } from "./Reducer/addPersonal";
import { AddRegister } from "./Reducer/addRegister";
import { AddRequirement } from "./Reducer/addRequirement";
import { AddUpdate } from "./Reducer/addUpdate";
import { AddUser } from "./Reducer/addUser";
import { AddVisa } from "./Reducer/addVisa";

const config = {
  key: "root",
  storage,
  debug: true
};

export const configureStore = () => {
  const store = createStore(
    persistCombineReducers(config, {
      login: Login,
      agent: Agent,
      branch: Branch,
      corporate: Corporate,
      holiday: Holiday,
      personal: Personal,
      register: Register,
      requirement: Requirement,
      update: Update,
      user: User,
      visa: Visa,
      addAgent: AddAgent,
      addBranch: AddBranch,
      addCorporate: AddCorporate,
      addHoliday: AddHoliday,
      addPersonal: AddPersonal,
      addRegister: AddRegister,
      addRequirement: AddRequirement,
      addUpdate: AddUpdate,
      addUser: AddUser,
      addVisa: AddVisa
    }),
    applyMiddleware(thunk)
  );

  const persistor = persistStore(store);

  return { persistor, store };
};
