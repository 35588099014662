import React from "react";
import Loadable from "react-loadable";
import Loader from "./views/Components/Loader/Loader";

import Fulllayout from "./layouts/fulllayout.jsx";

function Loading() {
  return (
    <div className="container">
      <div className="row">
        <div
          className="col"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "20vh"
          }}
        >
          <Loader />
        </div>
      </div>
    </div>
  );
}

const Dashboard = Loadable({
  loader: () => import("./views/starter/starter.jsx"),
  loading: Loading
});

const Application = Loadable({
  loader: () => import("./views/Components/Initiat/StatusTab"),
  loading: Loading
});

const AccountApplication = Loadable({
  loader: () => import("./views/Components/Account/Account"),
  loading: Loading
});

const RegisterTab = Loadable({
  loader: () => import("./views/Components/Initiat/RegisterTab"),
  loading: Loading
});

const HolidayRegister = Loadable({
  loader: () => import("./views/Components/Initiat/HolidayRegister"),
  loading: Loading
});

const Initiat = Loadable({
  loader: () => import("./views/Components/Initiat/Tabs/Initiat"),
  loading: Loading
});

const Personal = Loadable({
  loader: () => import("./views/Components/Initiat/Tabs/Personal"),
  loading: Loading
});

const VisaInfo = Loadable({
  loader: () => import("./views/Components/Initiat/Tabs/VisaInfo"),
  loading: Loading
});

const Requirements = Loadable({
  loader: () => import("./views/Components/Requirements/Requirements"),
  loading: Loading
});

const User = Loadable({
  loader: () => import("./views/Components/User/User"),
  loading: Loading
});

const Branch = Loadable({
  loader: () => import("./views/Components/Branch/Branch"),
  loading: Loading
});

const Corporate = Loadable({
  loader: () => import("./views/Components/Corporate/Corporate"),
  loading: Loading
});

const Agent = Loadable({
  loader: () => import("./views/Components/Agent/Agent"),
  loading: Loading
});

const Profile = Loadable({
  loader: () => import("./views/Components/Profile/Profile"),
  loading: Loading
});

const Search = Loadable({
  loader: () => import("./views/Components/Search/Search"),
  loading: Loading
});

const Report = Loadable({
  loader: () => import("./views/Components/Report/Report"),
  loading: Loading
});

const Holidays = Loadable({
  loader: () => import("./views/Components/Misc/Holidays"),
  loading: Loading
});

const Updates = Loadable({
  loader: () => import("./views/Components/Misc/Updates.js"),
  loading: Loading
});

const routes = [
  { path: "/", exact: true, name: "Home", component: Fulllayout },
  { path: "/dashboard", name: "Dashboard", component: Dashboard },
  {
    path: "/application",
    exact: true,
    name: "Application",
    component: Application
  },

  {
    path: "/accountapplication",
    exact: true,
    name: "Application",
    component: AccountApplication
  },
  {
    path: "/users",
    exact: true,
    name: "User",
    component: User
  },
  {
    path: "/profile",
    exact: true,
    name: "Profile",
    component: Profile
  },
  {
    path: "/corporate",
    exact: true,
    name: "Corporate",
    component: Corporate
  },
  {
    path: "/branch",
    exact: true,
    name: "Branch",
    component: Branch
  },
  {
    path: "/agent",
    exact: true,
    name: "Agent",
    component: Agent
  },
  {
    path: "/requirements",
    exact: true,
    name: "Requirements",
    component: Requirements
  },
  {
    path: "/search",
    exact: true,
    name: "Search",
    component: Search
  },
  {
    path: "/userapplication",
    exact: true,
    name: "User Application",
    component: HolidayRegister
  },
  {
    path: "/reports",
    exact: true,
    name: "Report",
    component: Report
  },
  {
    path: "/updates",
    exact: true,
    name: "Report",
    component: Updates
  },
  {
    path: "/holidays",
    exact: true,
    name: "Report",
    component: Holidays
  },
  {
    path: "/application/register/:id",
    exact: true,
    name: "Application",
    component: RegisterTab
  },
  {
    path: "/application/initiat/:id",
    exact: true,
    name: "Initiate",
    component: Initiat
  },
  {
    path: "/application/personal/:id",
    exact: true,
    name: "Personal Info",
    component: Personal
  },
  {
    path: "/application/visainfo/:id",
    exact: true,
    name: "Visa Info",
    component: VisaInfo
  }
];

export default routes;
