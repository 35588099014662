import * as ActionTypes from "../Types/ActionTypes";

export const Register = (
  state = {
    isLoading: true,
    errMess: null,
    register: [],
    completed: [],
    count: [],
    date: [],
    barcode: []
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.FETCH_REGISTER:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        register: action.payload
      };

    case ActionTypes.FETCH_COMPLETED:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        completed: action.payload
      };

    case ActionTypes.FETCH_COUNT:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        count: action.payload
      };
    case ActionTypes.FETCH_REPORT:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        report: action.payload
      };

    case ActionTypes.FETCH_BARCODE:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        barcode: action.payload
      };
    
    case ActionTypes.DELETE_REGISTER:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        barcode: []
      };

    case ActionTypes.REGISTER_LOADING:
      return { ...state, isLoading: true, errMess: null, register: [] };

    case ActionTypes.REGISTER_FAILED:
      return {
        ...state,
        isLoading: false,
        errMess: action.payload,
        register: [],
        count: [],
        report: []
      };

    default:
      return state;
  }
};
