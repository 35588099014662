import * as ActionTypes from "../Types/ActionTypes";

export const AddUpdate = (
  state = { isLoading: true, errMess: null, update: [], edit: [], delete: [] },
  action
) => {
  switch (action.type) {
    case ActionTypes.POST_UPDATE:
      let data = [];
      data.push(action.payload);
      return {
        ...state,
        isLoading: false,
        errMess: null,
        update: data,
        edit: [],
        delete: []
      };

    case ActionTypes.EDIT_UPDATE:
      let editData = [];
      editData.push(action.payload);
      return {
        ...state,
        isLoading: false,
        errMess: [],
        edit: editData,
        delete: [],
        update: []
      };

    case ActionTypes.DELETE_UPDATE:
      let deleteData = [];
      deleteData.push(action.payload);
      return {
        ...state,
        isLoading: false,
        errMess: null,
        delete: deleteData,
        update: [],
        edit: []
      };

    case ActionTypes.UPDATE_LOADING:
      return {
        ...state,
        isLoading: true,
        errMess: null,
        update: [],
        delete: []
      };

    case ActionTypes.UPDATE_FAILED:
      return {
        ...state,
        isLoading: false,
        errMess: action.payload,
        update: [],
        delete: []
      };

    default:
      return state;
  }
};
